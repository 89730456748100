/* Basic grid layout for the micro frontend application */
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px;
  }
  
  .text-explanation, .summary-table, .bar-chart-metrics, .comparative-bar-chart {
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  
  .text-explanation h2 {
    margin-bottom: 10px;
  }
  
  .summary-table th,
  .summary-table td {
    padding: 10px;
    text-align: left;
  }
  
  .summary-table th {
    background-color: #f9f9f9;
  }
  